import React from 'react'
import '../templates/FormPage';
import FormPage from '../templates/FormPage';
import {fire, generateUserDocument} from '../../firebase';
import {globalState} from '../../App'
import Loading from '../atoms/Loading';

export default function Register(props)
{
    const setUser = React.useContext(globalState).setUser;
    const [error, setError] = React.useState("");
    const [state, setState] = React.useState(0);
    const userRef = React.createRef();
    const emailRef = React.createRef();
    const passRef = React.createRef();
    const validateEmail = email =>
    {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    const registra = ()=>{
        let email = emailRef.current.value;
        let password = passRef.current.value;
        let username = userRef.current.value;
        if(validateEmail(email))
        {
            if(password.length >= 6)
            {
                setState(1)
                fire.auth().createUserWithEmailAndPassword(email, password).then(user =>{
                    generateUserDocument(user, email, username)
                }).then(user=>{
                    setState(0)
                    setUser(user);
                })
            }
            else
            {
                setError("Inserisci una password lunga almeno 6 caratteri")
            }
        }
        else
        {
            setError("Email non valida")
        }
    }
    if(state === 0)
    {
        return <FormPage>
            <h2 style={{textAlign:"center"}}>Registrati</h2>
            <input ref={userRef} placeholder="Username"/>
            <input ref={emailRef} placeholder="Email"/>
            <input ref={passRef} placeholder="Password" type="password"/>
            {error === "" ? <></> : <p className="error" style={{maxWidth:"184px"}}>{error}</p>}
            <button onClick={registra}>REGISTRATI</button>
        </FormPage>
    }
    else{
        return <FormPage>
            <div style={{marginBottom:"200px", position:"relative", top:"100px", display:"flex", alignItems:"center", width:"200px"}}>
                <Loading/>
            </div>
        </FormPage>
    }

}
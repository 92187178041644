import { faFireExtinguisher } from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase';
 
 var firebaseConfig = {
    apiKey: "AIzaSyCufysCBaj77zLd-5cF156ceLKfwHoihfk",
    authDomain: "babbo-natale-segreto.firebaseapp.com",
    projectId: "babbo-natale-segreto",
    storageBucket: "babbo-natale-segreto.appspot.com",
    messagingSenderId: "446984345424",
    appId: "1:446984345424:web:7c25efed185d91151c3e55",
    measurementId: "G-CQ1G6QLXPV"
};
// Initialize Firebase
export const fire = firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const db = fire.firestore();

export function generateUserDocument(data, email, username){
    let user = db.doc('utenti/'+data.user.uid).set({
        email: email,
        username: username,
    }).then(()=>{
        return getUserDocument(data.user);
    })
    return user;
}

export function getUserDocument(user){
    return db.doc('utenti/'+user.uid).get().then(utente=>{
        return utente.data();
    })
}

export function addGroup(name, admin)
{
    return db.collection('gruppi').add({
        nome: name,
        admin: admin,
        partecipanti: [admin]
    }).then(()=>{
        return
    })
}

export function nomeFromEmail(email)
{
    return db.collection('utenti').where('email', '==', email).get().then(snap=>{
        let user;
        snap.forEach(doc=>{
            user = doc.data().username;
        })
        return user;
    })
}


export function adminFromGruppo(gruppo)
{
    return db.doc('gruppi/'+gruppo).get().then(gruppo=>{
        return gruppo.data().admin
    }).then(admin=>{
        return db.collection('utenti').where('email', '==', admin).get();
    }).then(snap=>{
        let user;
        snap.forEach(doc=>{
            user = doc.data().username;
        })
        return user;
    })
}


export function addToGroup(group, email)
{
    return db.doc('gruppi/'+group).update({
        partecipanti: firebase.firestore.FieldValue.arrayUnion(email)
    }).then(()=>{
        return
    })
}

export function estrazione(gruppo, partecipanti)
{
    let estraibili = partecipanti.slice();
    partecipanti.forEach(partecipante=>{
        let estratto;
        do{
            estratto = estraibili[Math.floor(Math.random()*estraibili.length)]
        }while(estratto === partecipante)
        var index = estraibili.indexOf(estratto);
        if (index !== -1) {
            estraibili.splice(index, 1);
        }
        db.doc('gruppi/'+gruppo+'/estrazioni/'+partecipante).set({
            bimbo: estratto
        }).then(()=>{
            return
        })
    })
}

export function getBimbo(email, gruppo)
{
    return db.doc('gruppi/'+gruppo+'/estrazioni/'+email).get().then(snap=>{
        return snap.data();
    })
}
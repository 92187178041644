import React, { Children } from 'react'
import {nomeFromEmail, estrazione, getBimbo} from '../../firebase'
import Loading from '../atoms/Loading';

export default function GroupCard(props)
{
    const[copySuccess, setCopySucces] = React.useState(false);
    const linkRef = React.createRef();
    const [admin, setAdmin] = React.useState();
    const [partecipanti, setPartecipanti] = React.useState();
    const [bimbo, setBimbo] = React.useState();
    const copyToClipboard = (e) => {
        linkRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        if(copySuccess !== true)
        {
            setCopySucces(true);
            setTimeout(()=>{setCopySucces(false)},1000)
        }
    };

    React.useEffect(()=>{
        let part =[];
        props.gruppo.data.partecipanti.forEach(partecipante => {
            nomeFromEmail(partecipante).then((nome)=>
            {
                part.push(nome);
                if(part.length === props.gruppo.data.partecipanti.length)
                    setPartecipanti(part);
            })
        });
        getBimbo(props.user.email, props.gruppo.id).then(child=>{
            if(child !== undefined){
                return nomeFromEmail(child.bimbo)
            }
            else{
                return null
            }
        }).then(nome=>{
            setBimbo(nome);
        })
        nomeFromEmail(props.gruppo.data.admin).then((nome)=>
        {
            setAdmin(nome);
        })
    },[])

    return(
        <div className="groupCard">
            <div style={{display:"flex", alignItems:"center"}}>
                <h2 style={{color:"white"}}>{props.gruppo.data.nome}</h2>
                <p style={{marginLeft:"16px"}}>{props.gruppo.data.partecipanti.length} partecipanti</p>
            </div>
            {
                admin !== undefined && partecipanti !== undefined?
                <div style={{display:"flex", flexWrap:"wrap", alignItems:"center", marginBottom:"16px"}}>
                    <p className="partecipante">{admin} 👑</p>
                    {
                        partecipanti.map(partecipante =>{
                                if(partecipante !== admin)
                                return(
                                    <p className="partecipante" key={partecipante}>{partecipante}</p>
                                )
                        })
                    }
                </div>:
                <div style={{position:"relative", display:"flex", alignItems:"center", top:"3rem", marginBottom:"6rem"}}>
                    <Loading onlyEmoji/>
                </div>
            }
            {
                bimbo === null ?
                    <div>
                    {props.gruppo.data.admin === props.user.email?
                        props.gruppo.data.partecipanti.length >= 3 ?
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <button className="inverted" style={{width:"95%", marginBottom:"8px"}} onClick={()=>{estrazione(props.gruppo.id, props.gruppo.data.partecipanti)}}>ESTRAI</button>
                        </div>:<></>
                    :
                    <p>Attendi che l'amministratore del gruppo esegua l'estrazione</p>}
                    <input ref={linkRef} value={'https://babbonatalesegreto.it/group/'+props.gruppo.id} style={{width:"90%", margin:"auto"}} readOnly/>
                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", marginTop:"16px"}}>
                        <p className="label">Link invito</p>
                        <button className="inverted" onClick={copyToClipboard}>COPIA</button>
                    </div>
                    </div>
                :
                <div>
                    <p style={{marginBottom:"0", textAlign:"center"}}>SEI IL BABBO NATALE SEGRETO DI</p>
                    <h3 style={{color:"white", marginBottom:"8px", textAlign:"center"}}>{bimbo} 🎁</h3>
                </div>
            }
            {copySuccess?
            <div style={{position:"fixed", bottom:"8px", left:"50%", transform:"translateX(-50%)", backgroundColor:"var(--primary)", color:"white", padding:"16px", borderRadius:"16px"}}>
                Link copiato! 🌟
            </div>:
            <></>}
        </div>
    )
}
import React from 'react'
import './App.css';
import {fire, getUserDocument} from './firebase';
import Loading from './components/atoms/Loading'
import Header from './components/molecules/Header'
import Login from './components/pages/Login'
import Register from './components/pages/Register';
import Groups from './components/pages/Groups';
import Group from './components/pages/Group';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';

const globalState = React.createContext();

function App() {
  const [user, setUser] = React.useState();
  const [lastVisited, setLast] = React.useState("/");
  React.useEffect(()=>{
    fire.auth().onAuthStateChanged((uid)=>{
      if(uid !== null)
      {
        getUserDocument(uid).then(utente=>{
          setUser(utente);
        });
      }
      else
      {
        setUser(null);
      }
    })
  }, [])

  const setLastVisited = ()=>{
    setLast(window.location.pathname);
  }

  React.useState(()=>{
    console.log(user);
  }, [user])

  if(user === undefined)
  {
    return <div className="loadingScreen"><Loading/></div>
  }
  else
  {
    return (
      <globalState.Provider value={{user: user, setUser: setUser, setLast: setLastVisited}}>
        <BrowserRouter>
          <Header/>
          <Switch>
            <Route path="/login">{user === null ? <Login/>:<Redirect to={lastVisited}/>}</Route>
            <Route path="/register">{user === null ? <Register/>:<Redirect to={lastVisited}/>}</Route>
            <Route path="/groups" component={Groups}></Route>
            <Route path="/group/:groupID" component={Group}></Route>
            <Route path="/"></Route>
          </Switch>
        </BrowserRouter>
      </globalState.Provider>
    );
  }
}

export {globalState}
export default App;

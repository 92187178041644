import { getDefaultNormalizer } from '@testing-library/react'
import React from 'react'
import ReactLoading from 'react-loading';

export default function Loading(props)
{
    const tutteEmoji = ['🎅', '🦌', '🍪', '🥛', '❄️', '⛄', '🔥', '🎄', '🎁', '🔔', '🌟', '🕯️', '🎶']
    let intervallo;
    const [indice, setIndice] = React.useState(0);
    const [onlyEmoji, setEmoji] = React.useState(false);
    React.useEffect(()=>{
        if(intervallo === undefined)
        {
            if(props.onlyEmoji !== undefined)
            {
                setEmoji(props.onlyEmoji);
            }
            intervallo = setInterval(() => {
                setIndice(Math.floor(Math.random() * tutteEmoji.length))
            }, 300);
        }
        return ()=>{clearInterval(intervallo)}
    })
    if(!onlyEmoji)
    {
        return (
            <>
                <div className="assolutamenteCentrato"><ReactLoading type="spinningBubbles" color="var(--primary)" width="200px" height="200px"/></div>
                <div className="assolutamenteCentrato" style={{fontSize:"3rem"}}>{tutteEmoji[indice]}</div>
            </>
        )
    }
    else
    {
        return <div className="assolutamenteCentrato" style={{fontSize:"3rem"}}>{tutteEmoji[indice]}</div>
    }
}
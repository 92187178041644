import React from 'react'

export default function GenericPage(props)
{
    return(
        <div style={{margin:"16px"}}>
            <div style={{maxWidth:"1000px", margin:"auto", padding:"16px", backgroundColor:"white", borderRadius:"16px"}}>
                {props.children}
            </div>
        </div>
    )
}
import React from 'react'
import { Redirect, Link } from 'react-router-dom';
import {globalState} from '../../App';
import {addToGroup, adminFromGruppo} from '../../firebase';
import Loading from '../atoms/Loading';
import FormPage from '../templates/FormPage';

export default function Group(props)
{
    const user = React.useContext(globalState).user;
    const setLast = React.useContext(globalState).setLast;
    const [state, setState] = React.useState(0);
    const [admin, setAdmin] = React.useState();
    React.useEffect(()=>{
        setLast();
        if(user !== null)
        {
            addToGroup(props.match.params.groupID, user.email).then(()=>{
                setState(1);
            })
        }
        else
        {
            adminFromGruppo(props.match.params.groupID).then(nome=>{
                setAdmin(nome);
            })
        }
    }, [])
    if(user === null)
    {
        if(admin !== undefined)
        {
            return <FormPage>
            <h2>Oh oh oh!</h2>
            <p style={{marginTop:"0px"}}>Sembra che tu voglia iscriverti al gruppo di {admin}</p>
            <Link to="/login"><button>ACCEDI</button></Link>
            <Link to="/register"><button>ISCRIVITI</button></Link>
        </FormPage>
        }
        else
        {
            return<FormPage>
                <div style={{marginBottom:"200px", position:"relative", top:"100px", display:"flex", alignItems:"center", width:"200px"}}>
                    <Loading/>
                </div>
        </FormPage>
        }        
    }
    else{
        if(state === 0)
        {
            return<FormPage>
                <div style={{marginBottom:"200px", position:"relative", top:"100px", display:"flex", alignItems:"center", width:"200px"}}>
                    <Loading/>
                </div>
        </FormPage>
        }
        else
        {
            return <Redirect to="/groups"/>
        }
    }
}
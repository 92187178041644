import React from 'react'
import GenericPage from '../templates/GenericPage'
import {globalState} from '../../App'
import { Redirect } from 'react-router-dom';
import Loading from '../atoms/Loading';
import {db, addGroup} from '../../firebase'
import FormPage from '../templates/FormPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import GroupCard from '../molecules/GroupCard';

export default function Groups(props)
{
    const user = React.useContext(globalState).user;
    const setLast = React.useContext(globalState).setLast;
    const nomeRef = React.createRef();
    const [groups, setGroups] = React.useState();
    const [crea, setCrea] = React.useState(false);
    const [error, setError] = React.useState("");
    const handleAdd = ()=>{
        let nomeGruppo = nomeRef.current.value;
        if(nomeGruppo === "")
        {
            setError("Inserisci un nome per favore")
        }
        else
        {
            addGroup(nomeGruppo, user.email).then(()=>{
                setCrea(false);
            });
        }
    }
    React.useEffect(()=>{
        setLast()
        if(user!==null)
        {
            const unsub = db.collection('gruppi').where('partecipanti', 'array-contains', user.email).onSnapshot(gruppi =>{
                let gru = [];
                gruppi.forEach(gruppo => {
                    gru.push({id:gruppo.id, data:gruppo.data()})
                });
                setGroups(gru);
            })
            return ()=>{unsub()}
        }
    },[])
    if(user === null)
    {
        return(
            <Redirect to="/login"/>
        )
    }
    else if(crea)
    {
        return(        
            <FormPage>
                <div style={{display:"flex", justifyContent:"flex-end"}}><a onClick={()=>setCrea(false)}><FontAwesomeIcon icon={faTimes}/></a></div>
                <h2 style={{marginRight:"16px", textAlign:"center"}}>Crea gruppo</h2>
                <input ref={nomeRef} placeholder="Nome del gruppo"/>
                {error!==""?<p className="error">{error}</p>:<></>}
                <button onClick={handleAdd}>CREA</button>
            </FormPage>
        )
    }
    else
    {
        return(
        <GenericPage>
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", flexWrap:"wrap"}}>
                <h2 style={{marginRight:"16px"}}>I miei gruppi</h2>
                <button onClick={()=>{setCrea(true)}}>Crea gruppo</button>
            </div>
            {
                groups === undefined ?
                    <div style={{display:"flex", alignItems:"center", position:"relative", top:"150px", marginBottom:"270px"}}>
                        <Loading/>
                    </div>
                :
                    groups.length > 0 ?
                        <div style={{display:"flex", justifyContent:"center", flexWrap:"wrap", alignItems:"flex-start"}}>
                            {groups.map(gruppo=>{
                                return  <GroupCard key={gruppo.id} gruppo={gruppo} user={user}/>
                            })}
                        </div>
                    :
                        <p>Non sei ancora in nessun gruppo!</p>
            }
        </GenericPage>
        )
    }
}
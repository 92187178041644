import React from 'react'
import '../templates/FormPage';
import FormPage from '../templates/FormPage';
import Loading from '../atoms/Loading';
import {fire} from '../../firebase';
import {Link} from 'react-router-dom'

export default function Login(props)
{
    const [error, setError] = React.useState("");
    const [stato, setStato] = React.useState(0);
    const emailRef = React.createRef();
    const passRef = React.createRef();
    const accedi = ()=>{
        let email = emailRef.current.value;
        let password = passRef.current.value;
        setStato(1)
        fire.auth().signInWithEmailAndPassword(email, password).then(() =>{
            setStato(0)
        }).catch(()=>{
            setStato(0)
            setError("Si è verificato un errore 😞")
        })
    }
    return <FormPage>
        <h2 style={{textAlign:"center"}}>Accedi</h2>
        {
            stato === 0?
            <div>
                <input ref={emailRef} placeholder="Email"/>
                <input ref={passRef} placeholder="Password" type="password"/>
                {error === "" ? <></> : <p className="error" style={{maxWidth:"184px", marginBottom:"0"}}>{error}</p>}
                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <Link to="/register"><p>Registrati</p></Link>
                    <button onClick={accedi}>ACCEDI</button>
                </div>
            </div>:
            <div style={{marginBottom:"216px", width:"200px", display:"flex", alignItems:"center", position:"relative", top:"100px"}}>
                <Loading/>
            </div>
        }
    </FormPage>
}
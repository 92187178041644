import React from 'react'
import Snowfall from 'react-snowfall'

export default function FormPage(props)
{
    return(
        <div style={{backgroundColor:"var(--primary)", height:"100%", width:"100%", position:"absolute", top:"0", zIndex:"0", display:"flex", alignItems:"center"}}>
            <Snowfall />
            <div className="card assolutamenteCentrato">
                {props.children}
            </div>
        </div>
    )
}
import React from 'react'
import {Link} from 'react-router-dom'
import {globalState} from '../../App'

export default function Header(props)
{
    const user = React.useContext(globalState).user;
    return(
        <div className="navigation">
            <Link to="/"><h1 style={{marginLeft:"16px"}} className="siteTitle">Babbo Natale Segreto</h1></Link>
            <div style={{marginRight:"16px", marginTop:"16px", marginBottom:"16px"}}>
                {user !== null ? <Link to="/groups"><button>I miei gruppi</button></Link> : <Link to="/login">Accedi</Link>}
            </div>
        </div>
    )
}